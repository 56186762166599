import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import imgF1 from '../assets/image/l6-feature-icon1.svg'
import imgF2 from '../assets/image/l6-feature-icon2.svg'
import imgF6 from '../assets/image/l6-feature-icon6.svg'

const Features = () => {
  return (
    <div className="feature-section py-16 py-lg-25 dark-mode-texts">
      <Container>
        <Row className="justify-content-center">
          <Col md="8" lg="7" xl="6">
            <div className="section-title text-center mb-13 mb-lg-23">
              <h2 className="title gr-text-3">
                Promote your business on the Pointbee Platform.
              </h2>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm="6" lg="4" className="mb-12">
            <div className="feature-widget d-sm-flex">
              <div className="widget-icon gr-text-4 text-green mr-sm-9 mb-7 mb-lg-0">
                <img className="" src={imgF1} alt="business traffic arrows" />
              </div>
              <div className="content">
                <h3 className="title gr-text-7 mb-5">Drive traffic</h3>
                <p className="gr-text-9 gr-text-color-opacity">
                  Pointbee helps drive more customers to your brick-and-mortar
                  business.
                </p>
              </div>
            </div>
          </Col>
          <Col sm="6" lg="4" className="mb-12">
            <div className="feature-widget d-sm-flex">
              <div className="widget-icon gr-text-4 text-green mr-sm-9 mb-7 mb-lg-0">
                <img className="" src={imgF2} alt="business customers" />
              </div>
              <div className="content">
                <h3 className="title gr-text-7 mb-5">Earn loyalty</h3>
                <p className="gr-text-9 gr-text-color-opacity">
                  Rewarding customers will have them returning for more services
                  and products.
                </p>
              </div>
            </div>
          </Col>
          <Col sm="6" lg="4" className="mb-12">
            <div className="feature-widget d-sm-flex">
              <div className="widget-icon gr-text-4 text-green mr-sm-9 mb-7 mb-lg-0">
                <img className="" src={imgF6} alt="business revenue heart" />
              </div>
              <div className="content">
                <h3 className="title gr-text-7 mb-5">Increase revenue</h3>
                <p className="gr-text-9 gr-text-color-opacity">
                  Our platform offers great ROI due to the increased traffic and
                  repeat customers.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Features
