import React from 'react'

import PageWrapper from '../components/PageWrapper'
import Hero from '../sections/Hero'
// import Content1 from '../sections/Content1'
// import Content2 from '../sections/Content2'
import Process from '../sections/Process'
import Features from '../sections/Features'

const MobileApp = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: 'dark',
          button: 'null', // cta, account, null
        }}
        footerConfig={{
          style: 'style3', //style1, style2
        }}
      >
        <Hero />
        {/* TODO add these back when we get good images */}
        {/* <Content1 />
        <Content2 /> */}
        <Process />
        <div className="gradient-sections-wrapper bg-gradient-1">
          <Features />
        </div>
      </PageWrapper>
    </>
  )
}
export default MobileApp
